import React, { useContext } from 'react'

import AboutWorkAtArtificial from 'src/assets/images/AboutWorkAtArtificial.png'
import { AppContext } from 'src/contexts/AppContext'
import { ArCareersHero } from 'src/components/ArCareersHero'
import { ArContainer } from 'src/components/ArContainer'
import { ArExtraFeatures } from 'src/components/ArExtraFeatures'
import { ArQuickLinks } from 'src/components/ArQuickLinks'
import { ArSeo } from 'src/components/ArSeo'
import { ROUTES } from 'src/config/routes'
import SvgBeachAccess from 'assets/svg/beach-access.svg'
import SvgHealthcare from 'assets/svg/healthcare.svg'
import SvgTimeClock from 'assets/svg/time-clock.svg'
import SvgWorldPin from 'assets/svg/world-pin.svg'
import { ArCareersMission } from 'src/components/ArCareersMission'

const CareersPage = () => {
  const { jobsData } = useContext(AppContext)

  return (
    <>
      <ArSeo route={ROUTES.careers()} />
      <ArCareersHero />
      <ArCareersMission />
      <ArContainer className="md:pt-32 pt-8 pb-10">
        <ArExtraFeatures
          id="careers"
          smallSpaceBetween
          columns={[
            {
              title: 'Benefits at Artificial',
              paragraph: 'We offer the following benefits at Artificial, some of which are location dependent.',
              image: {
                png: AboutWorkAtArtificial,
              },
            },
            {
              tiles: [
                {
                  title: 'Hybrid working',
                  paragraph: 'We’re a hybrid working company with some roles fully remote.',
                  icon: <SvgWorldPin />,
                },
                {
                  title: 'Flexible hours',
                  paragraph: 'Whether you’re a night owl or an early riser, we know flexibility is important.',
                  icon: <SvgTimeClock />,
                },
                {
                  title: 'Generous holiday',
                  paragraph: 'Benefit from 28 days holiday per year plus all standard bank holidays.',
                  icon: <SvgBeachAccess />,
                },
                {
                  title: 'Insurance cover',
                  paragraph: 'UK employees receive private healthcare, life insurance and income protection.',
                  icon: <SvgHealthcare />,
                },
              ],
            },
          ]}
        />
      </ArContainer>
      {jobsData.length > 0 && (
        <>
          <ArContainer className="pt-8 md:pt-20">
            <ArQuickLinks title={`${jobsData.length} open positions`} paragraph="" links={jobsData} />
          </ArContainer>
        </>
      )}
        <ArContainer className="pb-16 font-sansSecond gap-8 flex opacity-70 md:flex-row flex-col text-sm">
        <p className="md:w-1/2">
          Agencies: Any unsolicited CVs will be treated as a gift and therefore any Terms & Conditions associated with
          the use of such CVs will be considered null and void.
        </p>
      </ArContainer>
    </>
  )
}

export default CareersPage
