import * as s from './ArCareersMission.module.scss'
import { ArContainer } from 'src/components/ArContainer'
import React from 'react'
import { TArCareersMissionProps } from './ArCareersMission.types'

import ArCareersPhoto1 from 'assets/images/ar-careers-photo-1.png'
import ArCareersPhoto2 from 'assets/images/ar-careers-photo-2.png'
import ArCareersPhoto3 from 'assets/images/ar-careers-photo-3.png'
import ArCareersPhoto5 from 'assets/images/ar-careers-photo-5.png'

const images = [
  { url: ArCareersPhoto1, alt: 'ITC Vegas Live interview stage setup' },
  { url: ArCareersPhoto3, alt: 'Product manager Moe on top of a big mountain somewhere' },
  { url: ArCareersPhoto2, alt: 'Photo of our nice espresso machine' },
  { url: ArCareersPhoto5, alt: 'Alexis, Matt and Andy winning an award' },
]

export const ArCareersMission: React.FC<TArCareersMissionProps> = () => {
  return (
    <ArContainer className={s.container} full>
      <header className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="w-full">
          <h2>
            Our mission is clear: leverage the best of today's technology to revolutionise how insurers and brokers
            operate tomorrow.
          </h2>
        </div>
        <div className="w-full">
          <p>
            At Artificial, we're not just building software - we're reshaping the future of the insurance industry. Our
            friendly and talented team value high performance, low ego and a sense of autonomy, coupled with a strong
            and sociable support network.
          </p>
        </div>
      </header>
      <div className="flex justify-between gap-4 overflow-x-scroll md:overflow-hidden pb-4 md:pb-0 over w-full object-cover">
        {images.map((image, index) => (
          <img key={index} src={image.url} alt={image.alt} className="w-72 h-72 rounded-2xl object-cover" />
        ))}
      </div>
    </ArContainer>
  )
}
