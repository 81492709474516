import ImageTeamArturKisiolek from 'src/assets/images/TeamArturKisiolek.png'
import ImageTeamEdHowkins from 'src/assets/images/TeamEdHowskins.png'
import ImageTeamDavidKing from 'src/assets/images/TeamDavidKing.png'
import ImageTeamJohnyBridges from 'src/assets/images/TeamJohnyBridges.png'
import ImageTeamMartin from 'src/assets/images/TeamMartin.png'

export const OUR_TEAM = [
  {
    name: 'Martin Reith',
    position: 'Chairman',
    image: ImageTeamMartin,
    linkedIn: 'https://www.linkedin.com/in/martin-reith-41444316/',
  },
  {
    name: 'David King',
    position: 'Co-Founder & Co-CEO',
    image: ImageTeamDavidKing,
    linkedIn: 'https://www.linkedin.com/in/malarkeyking',
  },
  {
    name: 'Johnny Bridges',
    position: 'Co-Founder & Co-CEO',
    image: ImageTeamJohnyBridges,
    linkedIn: 'https://www.linkedin.com/in/johnnybridges',
  },
  {
    name: 'Artur Kisiolek',
    position: 'CTO',
    image: ImageTeamArturKisiolek,
    linkedIn: 'https://www.linkedin.com/in/arturkisiolek',
  },
  {
    name: 'Ed Howkins',
    position: 'Chief Growth Officer',
    image: ImageTeamEdHowkins,
    linkedIn: 'https://www.linkedin.com/in/edward-howkins-b830ab58/'
  },
]
