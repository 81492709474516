import * as s from './ArExtraFeatures.module.scss'

import React, { useEffect, useState } from 'react'

import { TArExtraFeaturesProps } from './ArExtraFeatures.types'
import classNames from 'classnames'
import { isWebpSupported } from 'src/utils/isWebpSupported'
import { parseRichText } from 'src/utils/parseRichText'

export const ArExtraFeatures: React.FC<TArExtraFeaturesProps> = ({ id, smallSpaceBetween, columns }) => {
  const [imagesUrls, setImagesUrls] = useState<{ [key: string]: string }>()

  useEffect(() => {
    if (imagesUrls) return
    columns.forEach((item, i) => {
      if (item.image) {
        setImagesUrls({
          [i]: isWebpSupported() ? item.image.webp || item.image.png : item.image.png,
        })
      }
    })
  }, [columns, imagesUrls])

  return (
    <div
      id={id}
      className={classNames(s.container, {
        [s.smallSpaceBetween]: smallSpaceBetween,
      })}>
      {columns.map((item, i) => (
        <div key={i}>
          {item.title && <h2>{item.title}</h2>}
          {item.paragraph &&
            (typeof item.paragraph === 'string' ? (
              <p className="flex-1">{item.paragraph}</p>
            ) : (
              <p dangerouslySetInnerHTML={{ __html: parseRichText(item.paragraph) }} />
            ))}
          {imagesUrls?.[i] && <img src={imagesUrls[i]} className="object-cover rounded-2xl" alt="" />}
          {item.tiles && (
            <div className={s.tiles}>
              {item.tiles?.map((tile, i) => (
                <div key={i}>
                  {tile.icon}
                  <h5>{tile.title}</h5>
                  <p>{tile.paragraph}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}
