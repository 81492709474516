import { useEffect, useState } from 'react'
import { TEAMTAILOR_API_URL } from '../config/constants'

//mapped just required types from request
interface TeamTailorJob {
  links: {
    "careersite-job-url": string;
  }
  attributes: {
    title: string
  }
}

interface TeamTailorResponse {
  data: TeamTailorJob[]
}

export function useJobsData() {
  const [jobsData, setJobsData] = useState<{ title: string; url: string }[]>([])

  const fetchJobs = async () => {
    try {
      const response = await fetch(TEAMTAILOR_API_URL, {
        method: 'GET',
        headers: {
          'Authorization': `Token token=${process.env.GATSBY_TEAMTAILOR_API_TOKEN}`,
          'X-Api-Version': '20240404',
        }
      })
      const data: TeamTailorResponse = await response.json()

      if (response.ok && data) {
        setJobsData(
          data.data.map((job) => ({
            title: job.attributes.title,
            url: job.links["careersite-job-url"],
          }))
        )
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    fetchJobs()
  }, [])

  return {
    data: jobsData,
  }
}
