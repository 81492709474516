import * as s from './ArAboutOurTeam.module.scss'

import { ArContainer } from 'src/components/ArContainer'
import { OUR_TEAM } from 'src/config/ourTeam'
import React from 'react'
import SvgLinkedin from 'src/assets/svg/linkedin.svg'
import { TArAboutOurTeamProps } from './ArAboutOurTeam.types'

export const ArAboutOurTeam: React.FC<TArAboutOurTeamProps> = () => {
  return (
    <ArContainer className={s.container} full>
      <header>
        <h2>Our team</h2>
        <p>
          Made up of insurance and engineering experts, our team is well placed to take on the challenges of the
          insurance industry.
        </p>
      </header>
      <div className={s.team}>
        {OUR_TEAM.map((item, i) => (
          <div key={i}>
            <img className={s.teamImage} src={item.image} alt={item.name} />
            <div>
              <p>{item.name}</p>
              <span>{item.position}</span>
              <a href={item.linkedIn} target="_blank" rel="noreferrer" title="LinkedIn">
                <SvgLinkedin />
              </a>
            </div>
          </div>
        ))}
      </div>
    </ArContainer>
  )
}
