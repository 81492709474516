import * as s from './ArHomepageLogos.module.scss'

import { ArContainer } from 'src/components/ArContainer'
import LogoAxis from 'src/assets/images/logoAxis.png'
import LogoChaucer from 'src/assets/svg/logo-chaucer.svg'
import LogoConvex from 'src/assets/svg/logo-convex.svg'
import LogoTokioMarine from 'src/assets/images/LogoTokioMarine.png'
import React, { useEffect, useState } from 'react'
import { TArHomepageLogosProps } from './ArHomepageLogos.types'
import { isWebpSupported } from '../../utils/isWebpSupported'
import AonLogoWebp from 'src/assets/images/aon-logo.webp'
import AonLogo from 'src/assets/images/aon-logo.png'

export const ArHomepageLogos: React.FC<TArHomepageLogosProps> = () => {
  const [aonLogoUrl, setAonLogoUrl] = useState<string>()

  useEffect(() => {
    setAonLogoUrl(isWebpSupported() ? AonLogoWebp : AonLogo)
  }, [])
  return (
    <ArContainer className={s.container}>
      <h6>TRUSTED BY some of THE WORLD’S BEST INSURANCE COMPANIES</h6>
      <div className={s.logos}>
        <div>
          <img className={s.axisLogo} src={LogoAxis} alt="Axis" />
        </div>
        <div>
          <LogoChaucer />
        </div>
        <div>
          <LogoConvex />
        </div>
        <div>
          <img
            className={s.aonLogo}
            src={aonLogoUrl}
            alt="Aon insurance logo"
          />
        </div>
        <div>
          <img src={LogoTokioMarine} alt="Tokio Marine" />
        </div>
      </div>
    </ArContainer>
  )
}
